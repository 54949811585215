/**
 * Vue 確認モーダル拡張クラス
 * バリデーション込みの確認モーダルは validateVueを使用してください
 */
window.ConfirmVue = ModalVue.extend({
    data() {
        return {
            form: null,
            requestUrl: null,
            formData: null,
            html: new String(),

            isError: false,
            objects: new Array(),
        };
    },
    methods: {
        /**
         * 初期化
         * @param {HTMLFormElement} form
         */
         initFormValues(form) {
            this.form = form || this.form;
            this.requestUrl = this.requestUrl || this.form.action;
            this.formData = this.formData || new FormData(this.form);
        },
        /**
         * FormをPOSTし、バリデーション結果または、返り値を取得
         */
         async postData() {
            await axios.post(this.requestUrl, this.formData, {responseType: ''}).then(
                res => {
                    console.log(res.data);
                    if(res.data == null) {
                        this.isError = true;
                    }else {
                        this.data = res.data;
                        this.isError = false;
                    }
                }
            ).catch(
                err => {
                    console.log(err)
                    alert('connection error');
                    this.isError = true;
                }
            );
        },
        async getObjects(reqUrl, formData) {
            await axios.post(reqUrl, formData, {responseType: 'json'}).then(res => {
                if(res.data.status == false && res.data.abort) {
                    return this.abort(res.data.abort);
                }else {
                    this.setObjects(res.data.objects);
                }
            }).catch(err => {
                console.log(err);
                alert('connection error');
                this.isError = true;
            })
        },
        setObjects(objects) {
            this.objects = objects;
        },
    }
 })
