
/**
 * @see https://developer.mozilla.org/ja/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_tablet_or_desktop
 */
 window.isMobile = function() {
    const ua = window.navigator.userAgent.toLowerCase();
    return ua.indexOf('mobi') > -1;
}

window.getCsrf = function() {
    return $('meta[name="csrf-token"]').attr('content');
}

window.getHumanTimestamp = function() {
    const now = new Date();
    return (
        now.getFullYear()
        + ('0' + (now.getMonth() + 1)).slice(-2)
        + ('0' + now.getDay()).slice(-2)
        + ('0' + now.getHours()).slice(-2)
        + ('0' + now.getMinutes()).slice(-2)
    );
}

window.resizeString = function(str, len) {
    len = (len || 10);

    if(str == null || len < 0) {
        return null;
    }

    return str.slice(0, len) + '...';
}

/**
 * byte単位変換関数
 * @param {number} byte
 * @return {string}
 */
window.resizeByte = function(byte) {
    const BYTE_UNIT_LIST = ['byte', 'KB', 'MB', 'GB', 'TB', /*'PB'*/];

    const digits = byte.toString(10).length;
    for(let unit of BYTE_UNIT_LIST) {
        let idx = BYTE_UNIT_LIST.indexOf(unit);
        if(digits < (idx+1)*3+1) {
            let result = (byte/Math.pow(1024, idx)).toFixed(2);
            // 0値少数表示回避
            result = (result == 0) ? 0 : result;
            return `${result} ${unit}`;
        }
    }
    return (byte/Math.pow(1024, BYTE_UNIT_LIST.length -1)).toFixed(2) + ' ' + BYTE_UNIT_LIST[BYTE_UNIT_LIST.length -1];
}

/**
 * Bps(Byte per second)単位変換関数
 * bps(bit per second)でない
 * @param {number} byte
 * @param {number} sec
 * @return {string}
 */
window.resizeBps = function(byte, sec) {
    const BPS_UNIT_LIST = ['B', 'kKB', 'MB', 'GB', 'TB', /*'PB'*/];

    // 0除算回避
    if(sec === 0) {
        return '---';
    }

    const bps = parseInt(byte/sec);
    const digits = bps.toString(10).length;

    for(let unit of BPS_UNIT_LIST) {
        let idx = BPS_UNIT_LIST.indexOf(unit);
        if(digits < (idx+1)*3+1) {
            return `${(bps/Math.pow(1024, idx)).toFixed(2)} ${unit}/s`;
        }
    }
    return (bps/Math.pow(1024, BPS_UNIT_LIST.length -1)).toFixed(2) + ' ' + BYTE_UNIT_LIST[BYTE_UNIT_LIST.length -1]
}

window.getExtension = function(filename) {
    if (filename) {
        let idx = filename.lastIndexOf(".");
        if (idx >= 0) {
            return filename.substring(idx + 1).toLowerCase();
        }
    }
    return null;
}

window.getExtIconUri = function(ext, small) {
    ext = ext || "";
    small = small || false;

    const extensionMap = {
        "ai"   : 'ai',
        "aif"  : 'aif',
        "avi"  : 'avi',
        "bmp"  : 'bmp',
        "dwg"  : 'dwg',
        "dxf"  : 'dxf',
        "eps"  : 'eps',
        "xls"  : 'excel',
        "xlsx" : 'excel',
        "exe"  : 'exe',
        "fl"   : 'fl',
        "fla"  : 'fl',
        "swf"  : 'fl',
        "as"   : 'fl',
        "clr"  : 'fl',
        "act"  : 'fl',
        "gif"  : 'gif',
        "jpg"  : 'jpg',
        "lzh"  : 'lzh',
        "m4a"  : 'm4a',
        "mov"  : 'mov',
        "mp3"  : 'mp3',
        "mp4"  : 'mp4',
        "mpg"  : 'mpg',
        "pdf"  : 'pdf',
        "png"  : 'png',
        "ppt"  : 'point',
        "pptx" : 'point',
        "ps"   : 'eps',
        "psd"  : 'ps',
        "rar"  : 'rar',
        "sit"  : 'sit',
        "tif"  : 'tif',
        "txt"  : 'txt',
        "wav"  : 'wav',
        "wma"  : 'wma',
        "wmv"  : 'wmv',
        "doc"  : 'word',
        "docx" : 'word',
        "zip"  : 'zip',
        "ds_store" : 'apple',
    }

    if(extensionMap[ext] != undefined) {
        return small ? `/images/icons/${extensionMap[ext]}_s.png` : `/images/icons/${extensionMap[ext]}.png`;
    }

    return small ? '/images/icons/wild_s.png' : '/images/icons/wild.png';
}

window.calcTotalSize = function(jqElements) {
    let totalSize = 0;
    jqElements.each((index, element) => {
        if(element.files[0] === undefined) {
            return true;
        }
        totalSize += element.files[0].size
    })
    return totalSize;
}
