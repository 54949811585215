/**
 * Vue Form拡張
 * formを FormData化しaxiosでpostします。
 * valid: 確認モーダルを上げます。 => ユーザによるsubmitでアクションを完了させます。
 * invalid: formをsubmitします。 => バリデーションを表示させます。
 * refs [form, modal, is_confirmed]
 */
 window.FormVue = Vue.extend({
    // el: '',
    data() {
        return {
            // v-modelを利用する場合、{{}}直書きでは特殊文字列がエスケープされるのでhtmlから初期値を取得すること。
            // property: $("[name='']").val()
            responseData: new Object(),
        }
    },
    methods: {
        async postData() {
            return axios.post(this.$refs.form.action, new FormData(this.$refs.form), {responseType: ''})
        },
        renderErrors(errs) {

        },
        handleConnectError(err) {
            console.log(err)
            alert('接続に失敗しました。');
        },
        showModal() {
            $(this.$refs.modal).modal('show');
        },
        hideModal() {
            $(this.$refs.modal).modal('hide');
        },
        /**
         * htmlのバリデーションをかけます
         */
        validate() {
            if(this.$refs.form.checkValidity() === false) {
                if(this.$refs.form.reportValidity) {
                    this.$refs.form.reportValidity();
                }else {
                    // IE, Edge
                    alert('必須項目を入力してください。')
                }
                return false;
            }
            return true;
        },
        confirm() {
            if(this.validate()) {
                this.postData().then(res => {
                    console.log(res.data);
                    this.responseData = res.data;
                    if(res.data.valid) {
                        this.showModal();
                        return;
                    }
                    this.submit();
                }).catch(err => {console.log('error=',err); this.handleConnectError(err); });
            }
        },
        submit() {
            // 確認フラグを有効化
            this.$refs.is_confirmed.value = 1
            $(this.$refs.form).trigger('submit')
        },
    },
})
