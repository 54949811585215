/**
 * Vue モーダル拡張クラス
 */
window.ModalVue = CustomVue.extend({
    methods: {
        fill() {},
        showModal() {
            $(this.$refs.modal).modal('show');
        },
        hideModal() {
            $(this.$refs.modal).modal('hide');
        },
        abort(code) {
            return location.href = getURI(`errors/${code}`, false);
        },
    },
})
