/**
 * Vue カスタムクラス
 */
window.CustomVue = Vue.extend({
    // methods: {
    //     reset() {
    //         Object.assign(this.$data, this.$options.data.call(this));
    //     },
    // },
})
