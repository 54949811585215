/**
 * Vue バリデーション拡張クラス
 */
window.ValidateVue = ModalVue.extend({
    data() {
        return {
            form: null,
            requestUrl: null,
            formData: null,
            htmlValidation: true,
            valid: false,

            scrollSeconds: 500,

            view: new String(),
            html: new String(),
            data: null,
            objects: new Array(),
            errors: new Array(),
        };
    },
    methods: {
        reset() {
            this.form = null;
            this.requestUrl = null;
            this.formData = null;
            this.view = new String();
            this.errors = new Array();

        },
        /**
         * 初期化
         * @param {HTMLFormElement} form
         */
        initFormValues(form) {
            this.form = form || this.form;
            this.requestUrl = this.requestUrl || this.form.action;
            this.formData = this.formData || new FormData(this.form);
        },
        /**
         * HTML本来のバリデーション
         * @returns
         */
        htmlValidate() {
            if(this.form == null) return;
            if(this.form.checkValidity() === false) {
                if(this.form.reportValidity) {
                    this.form.reportValidity();
                }else {
                    // IE, Edge
                    alert('必須項目を入力してください。')
                }
                return false;
            }
            return true;
        },
        /**
         * FormをPOSTし、バリデーション結果または、返り値を取得
         */
        async postData() {
            await axios.post(this.requestUrl, this.formData, {responseType: ''}).then(
                res => {
                    console.log(res.data);
                    if(res.data.status == false) {
                        if(res.data.abort) {
                            return this.abort(res.data.abort);
                        }
                        this.valid = false;
                        this.errors = res.data.errors;
                    }else {
                        this.valid = true;
                        this.data = res.data;
                        this.objects = res.data.objects;
                    }
                }
            ).catch(
                err => {
                    console.log(err)
                    alert('connection error');
                }
            );
        },
        /**
         * バリデーション実行
         * @returns
         */
         async validate() {
            if(this.form == null || this.requestUrl == null || this.formData == null) return;

            if(this.htmlValidation) {
                if(this.htmlValidate() == false) {
                    this.valid = false;
                    return;
                }else {
                }
            }

            await this.postData();
        },
        /**
         * .is-invalid:first へスクロール
         */
        scrollInvalid() {
            if($('.is-invalid').length) {
                const pos = $($('.is-invalid').get(0)).offset().top;
                $('html,body').animate({scrollTop: pos}, this.scrollSeconds);
            }
        },
        async doValidate() {
            this.reset();
            this.initFormValues(this.$refs.form);
            await this.validate();
            if(this.valid) {
                this.view = this.data;
                this.html = this.data;
                this.showModal();
            }else {
                this.scrollInvalid();
            }
        },
        async doConfirm() {
            console.log('doconfirm')
            this.reset();
            this.initFormValues(this.$refs.form);
            await this.validate();
            if(this.valid) {
                this.fill();
                this.showModal();
            }else {
                this.scrollInvalid();
            }
        },
        // async validate(form) {
        //     const reqUrl = form.action
        //     const formData = new FormData(form);
        //     await axios.post(reqUrl, formData, {responseType: 'json'}).then(res => {
        //         console.log(res.data);
        //         if(res.data.status == false) {
        //             if(res.data.abort) {
        //                 return this.abort(res.data.abort);
        //             }
        //             this.valid = false;
        //             this.setErrors(res.data.errors);
        //         }else {
        //             this.valid = true;
        //             this.setObjects(res.data.objects);
        //         }
        //     }).catch(err => {
        //         console.log(err)
        //         alert('connection error');
        //     });
        // },
        // async getObjects(formData, reqUrl) {
        //     await axios.post(reqUrl, formData, {responseType: 'json'}).then(res => {
        //         console.log(res.data);
        //         if(res.data.status == false && res.data.abort) {
        //             return this.abort(res.data.abort);
        //         }else {
        //             this.setObjects(res.data.objects);
        //         }
        //     }).catch(err => {
        //         console.log(err)
        //         alert('connection error');
        //     })
        // },
        // setObjects(objects) {
        //     this.objects = objects;
        // },
        // setErrors(errors) {
        //     this.errors = errors;
        // },
        // async doConfirm() {
        //     this.reset();
        //     const form = this.$refs.form;
        //     if(this.htmlValidate(form)) {
        //         await this.validate(form);
        //         if(this.valid) {
        //             this.fill();
        //             this.showModal();
        //         }else {
        //             const pos =  $($('.is-invalid').get(0)).offset().top;
        //             $('html,body').animate({scrollTop: pos}, 500);
        //         }
        //     }
        // },
        // htmlValidate(form) {
        //     if(form.checkValidity() === false) {
        //         if(form.reportValidity) {
        //             form.reportValidity();
        //         }else {
        //             // IE, Edge
        //             alert('必須項目を入力してください。')
        //         }
        //         return false;
        //     }
        //     return true;
        // },
    }
})
